import React, { Component } from "react"
import { graphql } from "gatsby"

import PlaceView from "../views/places"

class PlaceWrapper extends Component {
  render() {
    const data = this.props.data
    const location = this.props.location
    return <PlaceView data={data} location={location} />
  }
}

export default PlaceWrapper

export const placesQuery = graphql`
  query {
    allMdx(filter: {frontmatter: {closed: {ne: true}, draft: {eq: false}}, fields: {sourceName: {eq: "places"}}}, sort: {fields: fields___slug, order: ASC}) {
      edges {
        node {
          id
          fields {
            slug
            sourceDirectory
          }
          frontmatter {
            name
            summary
            speciality
            tags
            price
            occasions
            holidays
            closed
            article {
              title
              url
            }
            locations {
              street
              suburb
              region
              postcode
              country
              hours {
                mon
                tue
                wed
                thu
                fri
                sat
                sun
              }
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        holidayFilters
      }
    }
  }
`
