import React from "react"
import 'twin.macro'

const PlaceGrid = ({children}) => (
  <div tw="grid px-4 gap-y-6 gap-x-4 py-8 grid-cols-2 md:(gap-y-6 gap-x-4) lg:(grid-cols-3) xl:(grid-cols-4) 2xl:(grid-cols-5)">
    {children}
  </div>
)

export default PlaceGrid
