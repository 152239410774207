import React from "react"
import tw from 'twin.macro'

export default function Filter({
  sortRecent,
  heading,
  data,
  filters,
  setFilters,
}) {
  return (
    <div tw="pb-4">
      <h2 tw="font-sans text-base mb-2">{heading}</h2>
      <ul tw="list-none m-0 p-0">
      {data
        .map(([c, count]) => (
          <li tw="m-0" key={c}>
            <button
              css={[
                tw`flex items-center appearance-none font-sans m-0 py-0.5 pr-2 px-0 border-0 bg-transparent text-sm block w-full text-left rounded focus:(outline-none)`,
                filters.has(c) ? tw`text-blue-500` : tw`text-thirdgray-500`
              ]}
              onClick={() => {
                if (filters.has(c)) {
                  filters.delete(c)
                  setFilters(filters)
                } else {
                  setFilters(filters.add(c))
                }
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="10"
                height="10"
                tw="mr-1 inline-flex">
                {
                  filters.has(c) ? <g xmlns="http://www.w3.org/2000/svg" fill="none" fill-rule="evenodd"><rect fill="#0073e6" width="10" height="10" rx="2"/><path fill="#FFF" fill-rule="nonzero" d="M4 5.44L2.53 3.97 1.47 5.03 4 7.56l4.03-4.03-1.06-1.06z"/></g> : <path fill="#A8A8A8" d="M2 0h6a2 2 0 012 2v6a2 2 0 01-2 2H2a2 2 0 01-2-2V2a2 2 0 012-2zm0 1a1 1 0 00-1 1v6a1 1 0 001 1h6a1 1 0 001-1V2a1 1 0 00-1-1H2z" />
                }                
              </svg>
              {c}
              <span tw="float-right">{count}</span>
            </button>
          </li>
        ))
      }
      </ul>
    </div>
  )
}
