import React from "react"
import 'twin.macro'
import CardHero from "./card_hero"
import { Link } from "gatsby"

function PlaceItem({place}) {
  const suburbs = Array.from(new Set(place.frontmatter.locations.map(({suburb}) => ( suburb ))))
  const hasArticle = (place.frontmatter.article !== null)
  return (
    <Link
      to={place.fields.slug}
      tw="block no-underline relative color[inherit]">
      {hasArticle &&
        <div
          title={`${place.frontmatter.name} has a feature on Down By 12th.`}
          tw="absolute z-10 top[10px] right[10px]">
          <svg fill="#fff" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16px" height="16px">
            <path d="M 2 2 L 2 12 C 2 13.097656 2.902344 14 4 14 L 12 14 C 13.097656 14 14 13.097656 14 12 L 14 5 L 11 5 L 11 2 Z M 3 3 L 10 3 L 10 12 C 10 12.367188 10.109375 12.703125 10.28125 13 L 4 13 C 3.441406 13 3 12.558594 3 12 Z M 4 5 L 4 6 L 9 6 L 9 5 Z M 11 6 L 13 6 L 13 12 C 13 12.558594 12.558594 13 12 13 C 11.441406 13 11 12.558594 11 12 Z M 4 7 L 4 8 L 6 8 L 6 7 Z M 7 7 L 7 8 L 9 8 L 9 7 Z M 4 9 L 4 10 L 6 10 L 6 9 Z M 7 9 L 7 10 L 9 10 L 9 9 Z M 4 11 L 4 12 L 6 12 L 6 11 Z M 7 11 L 7 12 L 9 12 L 9 11 Z"/>
          </svg>
        </div>
      }
      <CardHero path={place.fields.sourceDirectory} alt={place.frontmatter.name}/>
      <h2 tw="text-base font-serif my-1 md:(text-xl) lg:(text-2xl)">{place.frontmatter.name}</h2>
      <div tw="uppercase text-thirdgray-500 tracking-wider text-xs md:(text-sm)">
        {suburbs.join(' · ')}
      </div>
    </Link>
  )
}

export default PlaceItem
