import React, { Component } from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import Rrsm from "../../utils/reach-router-state-manager"
import queryString from "query-string"

import FilteredPlaces from "./filtered-places"

class PlacesPage extends Component {
  shouldComponentUpdate(nextProps) {
    // Prevents double rendering according to a comment on Github
    // https://github.com/gatsbyjs/gatsby/blob/master/www/src/views/starter-library/index.js#L11
    return JSON.stringify(this.props) !== JSON.stringify(nextProps)
  }

  render() {
    const { location } = this.props
    const urlState = queryString.parse(location.search)

    const contextualOpengraph =
      urlState.h !== undefined
        ?
          `/directory/australia_day.jpg`
        :
          `/directory.jpg`
    
    const contextualTitle =
      urlState.h !== undefined
        ?
          `Place directory — Open January 26`
        :
          `Place directory`

    const contextualDescription =
      urlState.h !== undefined
        ?
          `View our living guide to the best in Perth’s hospitality and food scene, open on January 26.`
        :
          `View our living guide to the best in Perth’s hospitality and food scene.`

    return (
      <Layout location={location} styleContext="FilterDetail">
        <Seo
          title={contextualTitle}
          description={contextualDescription}
          image={contextualOpengraph}/>
        <Rrsm
          {...this.props}
          location={location}
          render={({ setURLState, urlState }) => (
            <FilteredPlaces
              {...this.props}
              setURLState={setURLState}
              urlState={urlState}
            />
          )}
        />
      </Layout>
    )
  }
}

export default PlacesPage
