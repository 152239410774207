import tw from 'twin.macro'
import React, { Component } from "react"

import Filter from "./filter"
import HolidayFilter from "./holidayFilter"
import PlaceList from "./place-list"
import Footer from "../../components/footer"

import { PrimaryButton } from "../../components/button.js"
import { searchSuggestions } from "../../utils/helpers"
import DebounceInput from "../../components/debounce-input"

export default class FilteredPlaces extends Component {
  state = {
    placesToShow: 20,
    showFiltersOnMobile: false
  }

  setFiltersCategory = filtersCategory => {
    this.props.setURLState({ c: Array.from(filtersCategory)})
  }

  setOccasionCategory = occasionCategory => {
    this.props.setURLState({ o: Array.from(occasionCategory)})
  }

  setHolidayCategory = holidayCategory => {
    this.props.setURLState({ h: Array.from(holidayCategory)})
  }

  resetFilters = () => this.props.setURLState({ c: [], s: ``, o: [], h: [] })

  toggleMobileFilterDisplay = () => {
    this.setState({
      showFiltersOnMobile: !this.state.showFiltersOnMobile
    })
  }

  showMorePlaces = places => {
    let showAll =
      this.state.placesToShow + 10 > places.length ? places.length : false

    this.setState({
      placesToShow: showAll ? showAll : this.state.placesToShow + 10,
    })
  }

  onChangeUrlWithText = value => this.props.setURLState({ s: value })

  render() {
    const { data, urlState } = this.props

    const {
      setFiltersCategory,
      setOccasionCategory,
      setHolidayCategory,
      resetFilters,
    } = this

    const filtersCategory = new Set(
      Array.isArray(urlState.c) ? urlState.c : [urlState.c]
    )

    const occasionCategory = new Set(
      Array.isArray(urlState.o) ? urlState.o : [urlState.o]
    )

    const holidayCategory = new Set(
      Array.isArray(urlState.h) ? urlState.h : [urlState.h]
    )

    const filters = new Set(
      [].concat(
        ...[filtersCategory, occasionCategory, holidayCategory].map(set =>
          Array.from(set))
      )
    )
    // Load in places data
    let places = data.allMdx.edges

    const showHolidayFilters = data.site.siteMetadata.holidayFilters
    
    if (urlState.s.length > 0) {
      places = places.filter(place =>
        JSON.stringify(place.node)
          .toLowerCase()
          .includes(urlState.s.toLowerCase())
      )
    }

    if (filtersCategory.size > 0) {
      places = filterByCategories(places, filtersCategory)
    }

    if (occasionCategory.size > 0) {
      places = filterByOccasion(places, occasionCategory)
    }

    if (holidayCategory.size > 0) {
      places = filterByHoliday(places, holidayCategory)
    }

    return (
      <div
        tw="h-screen mt-12 grid border-solid border-0 border-t border-thirdgray-200 dark:(border-thirdgray-dark-200)  relative grid-template-columns[1fr] grid-template-rows[68px auto 1fr] grid-template-areas['head' 'filter' 'main'] lg:(grid-template-columns[244px 1fr] grid-template-rows[60px 1fr] border-t-0 grid-template-areas['filter head' 'filter main'] mt-0 )">
        <div
          css={[
            tw`p-4 grid-area[filter] border-solid border-0 border-thirdgray-200 dark:(border-thirdgray-dark-200)  border-b lg:(border-r border-b-0)`,
            (this.state.showFiltersOnMobile) ? tw`block` : tw`hidden lg:(block)`
          ]}>
          <div tw="lg:(h-12)">
            {(filters.size > 0 || urlState.s.length > 0) && (
              <PrimaryButton
                onClick={resetFilters}
                tw="pl-3 mb-4 flex items-center">
                <svg
                  width='10'
                  height='10'
                  xmlns='http://www.w3.org/2000/svg'
                  tw='mr-1'>
                  <g fill='#0073e6' fillRule='nonzero'>
                    <path d='M.293 1.707l8 8 1.414-1.414-8-8z'/>
                    <path d='M1.707 9.707l8-8L8.293.293l-8 8z'/>
                  </g>
                </svg>
                Reset Filters
              </PrimaryButton>
            )}
          </div>
          <Filter
            heading="Occasion"
            data={Array.from(
              count(places.map(({ node: place }) => place.frontmatter.occasions))
            )}
            filters={occasionCategory}
            setFilters={setOccasionCategory}
            />  
          <Filter
            heading="Features"
            data={Array.from(
              count(places.map(({ node: place }) => place.frontmatter.tags))
            )}
            filters={filtersCategory}
            setFilters={setFiltersCategory}
            sortRecent={urlState.sort === `recent`}
            />
          {showHolidayFilters &&
            <HolidayFilter
              heading="Holidays"
              data={Array.from(
                count(places.map(({ node: place }) => place.frontmatter.holidays))
              )}
              filters={holidayCategory}
              setFilters={setHolidayCategory}
              />
          }
        </div>
        <div
          tw="bg-thirdgray-50 grid-area[head] p-4 flex items-center justify-between relative z-10 border-solid border-0 border-b border-thirdgray-200 dark:(border-thirdgray-dark-200 bg-thirdgray-dark-50)">
          <div>
            <strong>{places.length}</strong> {pluralise(places.length, 'place')}
            {(filters.size > 0 || urlState.s.length > 0) && (
              <span tw="text-xs text-thirdgray-500"> (filtered)</span>
            )}
          </div>
          <div tw="flex">
            <DebounceInput
              value={urlState.s}
              onChange={this.onChangeUrlWithText}
              placeholder={`Try ‘${searchSuggestions[Math.floor(Math.random() * searchSuggestions.length)]}’`}
              aria-label="Search places"
            />
            <button
              tw="appearance-none p-0 ml-2 bg-transparent border-0 lg:(hidden) focus:(outline-none)"
              onClick={() => this.toggleMobileFilterDisplay()}>
                <svg
                  width="22"
                  height="22"
                  xmlns="http://www.w3.org/2000/svg">
                  <g
                    fillRule="evenodd"
                    style={{ fill: 'currentColor'}}
                    css={[
                      (filters.size > 0 ? tw`text-blue-500` : tw`text-thirdgray-500`)
                    ]}>
                    <rect y="2" width="22" height="2" rx="1"/>
                    <rect x="15" width="3" height="6" rx="1"/>
                    <rect y="10" width="22" height="2" rx="1"/>
                    <rect x="5" y="8" width="3" height="6" rx="1"/>
                    <rect y="18" width="22" height="2" rx="1"/>
                    <rect x="11" y="16" width="3" height="6" rx="1"/>
                  </g>
                </svg>
            </button>
          </div>
        </div>
        <div
          tw="grid-area[main] relative">
          <div tw="lg:(absolute inset-0 z-0 overflow-y-scroll -webkit-overflow-scrolling[touch] pb-24)">
            <PlaceList
              urlState={urlState}
              places={places}
              count={this.state.placesToShow}
            />
            {this.state.placesToShow < places.length && (
              <button
                tw="appearance-none border-0 m-0 p-2 px-12 rounded text-sm font-bold max-w-xl my-10 mx-auto block text-white bg-blue-600"
                onClick={() => this.showMorePlaces(places)}>
                Show More
              </button>
            )}
            <Footer/>
          </div>
        </div>
      </div>
    )
  }
}

//utility functions
const pluralise = (count, noun, suffix = 's') =>
  `${noun}${count !== 1 ? suffix : ''}`;

function count(arrays) {
  let counts = new Map()

  for (let categories of arrays) {
    if (!categories) continue

    for (let category of categories) {
      if (!counts.has(category)) {
        counts.set(category, 0)
      }

      counts.set(category, counts.get(category) + 1)
    }
  }

  return counts
}

function filterByCategories(list, categories) {
  let places = list

  places = places.filter(({ node: place }) =>
    isSuperset(place.frontmatter.tags, categories)
  )
  return places
}

function filterByOccasion(list, occasions) {
  let places = list

  places = places.filter(({ node: place }) =>
    isSuperset(place.frontmatter.occasions, occasions)
  )
  return places
}

function filterByHoliday(list, holidays) {
  let places = list

  places = places.filter(({ node: place }) =>
    isSuperset(place.frontmatter.holidays, holidays)
  )
  return places
}

function isSuperset(set, subset) {
  for (var elem of subset) {
    if (!set.includes(elem)) {
      return false
    }
  }
  return true
}
