import React from "react"
import 'twin.macro'

import PlaceGrid from "../../components/place/grid"
import PlaceItem from "../../components/place/card"

const PlaceList = ({ urlState, places, count, sortRecent }) => {
  if (!places.length) {
    // empty state

    const emptyStateReason =
      urlState.s !== ``
        ? urlState.s // if there's a search term
        : urlState.c && !Array.isArray(urlState.c)
        ? urlState.c
        : `matching`

    return (
      <div tw="text-center p-16">
        <h1 tw="text-lg">No <strong>{emptyStateReason}</strong> places found!</h1>
      </div>
    )
  }

  if (count) {
    places = places.slice(0, count)
    return (
      <PlaceGrid>
        {places.map(({ node: place }) => {
          return (
            place.frontmatter && (
              <PlaceItem
                key={place.id}
                place={place}/>
            )
          )
        })}
      </PlaceGrid>
    )
  }
}

export default PlaceList
