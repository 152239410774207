import React from "react"
import 'twin.macro'

const LinkButton = ({
  variant = `link`,
  ...props
}) =>
  <a
    {...props}
    tw="border-0 appearance-none text-sm rounded text-sm px-3 py-1 leading-normal text-center mb-2 transition transform bg-blue-100 text-blue-700 transition scale-100 active:(outline-none scale-95) dark:(bg-blue-900 text-blue-50)">
    {props.children}
  </a>

const PrimaryButton = ({
  variant = `primary`,
  ...props
}) =>
  <button
    {...props}
    tw="border-0 appearance-none text-sm rounded text-sm px-3 py-1 leading-normal text-center mb-2 transition transform bg-blue-100 text-blue-700 transition scale-100 active:(outline-none scale-95) dark:(bg-blue-900 text-blue-50)">
    {props.children}
  </button>

export { LinkButton, PrimaryButton }