import React from "react"
import 'twin.macro'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

const CardHero = (props) => (
  <StaticQuery
    query={graphql`
      query {
        images: allFile(filter: {name: {eq: "hero"}}) {
          edges {
            node {
              relativeDirectory
              name
              childImageSharp {
                fluid(maxWidth: 600) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    `}

    render={(data) => {
      const image = data.images.edges.find(n => {
        return n.node.relativeDirectory.includes(props.path);
      });
      if (!image) { return null; }
      const imageFluid = image.node.childImageSharp.fluid;
      return (
        <div
          tw="mb-2 rounded overflow-hidden backface-visibility[hidden] transform[translate3d(0, 0, 0)] md:(mb-4)">
          <Img
            alt={props.alt}
            fluid={imageFluid}
            tw="transform scale-100 transition[transform 6s ease] hover:(scale-125)"
          />
        </div>
      );
    }}
  />
)

export default CardHero
